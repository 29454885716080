
@import url(https://fonts.googleapis.com/css?family=PT+Mono&subset=cyrillic);

@font-face{
  font-family: "PT Mono";
  src: url('/fonts/pt-mono-v6-latin-regular.eot');
  src: local('PT Mono'), local('PTMono-Regular'),
    url('/fonts/pt-mono-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/pt-mono-v6-latin-regular.woff2') format('woff2'),
    url('/fonts/pt-mono-v6-latin-regular.woff') format('woff'),
    url('/fonts/pt-mono-v6-latin-regular.ttf') format('truetype');
  
  src: url(/fonts/PTMonoBold.woff) format("woff"), url(/fonts/PTMonoBold.ttf) format("truetype"), url(/fonts/PTMonoBold.eot?#iefix) format("embedded-opentype");
  font-stretch: normal;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0020-F6D4;
}

$body-font-family: "PT Mono";
$input-placeholder-color: #14679e;
$card-divider-background: #eff9ff;

@import "~foundation-sites/assets/foundation.scss";
@import "~react-tabs/style/react-tabs.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.details{
  list-style-type: none;
}

.license{
  border-bottom: 1px dotted;
}

.result {
  opacity: 0.8;
  &:hover{
    opacity: 1;
  }
  h5{
    color: $primary-color;
  }
}

.btn-icon{
  display: block;
  text-align: center;
  width: 100% !important;
}

.documents{
  a{
    display: block;
    text-align: center;
  }
}
.result-list{
}
